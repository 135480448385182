(function (w, d) {
    var starRatings = {
        init: function () {
            var __tnt = w.__tnt || {},
                oForm = d.getElementById('star-ratings-form'),
                oFormSelector = d.querySelector("#star-ratings-form"),
                sUUID = oForm.getAttribute("data-uuid"),
                sFormInput = d.getElementById('star-ratings-hidden-type'),
                sFormActionValue = d.getElementById('star-ratings-action'),
                sStorage = localStorage.getItem("star-ratings-" + sUUID),
                sStorageTime = localStorage.getItem("time-star-rated-" + sUUID),
                oCountWrapper = d.querySelector(".count-" + sStorage),
                oWrapper = d.querySelector(".star-ratings-wrapper"),
                aClasses = oWrapper.classList,
                bReactionClass = aClasses.contains("logged_in");
                
            // Send star rating
            function sendData() {
                var sFormData = new FormData(oForm),
                    sXHR = new XMLHttpRequest(),
                    sFormAction = oForm.getAttribute("action"),
                    sFormInput = d.getElementById('star-ratings-hidden-type');

                sXHR.open("POST", sFormAction);
                sXHR.send(sFormData);
            }
            
            // If requires logged in user    
            if(bReactionClass) {
                if( __tnt.user.loggedIn ){
                    if (d.querySelector('.logged-in-reminder')) {
                        d.querySelector('.logged-in-reminder').remove();
                    }
                 } else {
                     aClasses.add('static-height');
                     d.querySelectorAll('.star-rating-input').forEach(el => el.disabled = true);
                     var sCurrentURL = w.location.href;
                     oFormSelector.onclick = function() {
                        w.location.href="/users/login?referer_url=" + sCurrentURL;
                     };
                 }
             }

            // Hide count if zero
            var oCounts = d.querySelectorAll('.star-ratings-count'), i;
            for (i = 0; i < oCounts.length; ++i) {
                var iCount = oCounts[i];
                if (iCount.innerHTML === "0") {
                    iCount.classList.add("hide");
                }
            }

            // Check local storage for previous vote and highlight
            if (sStorage) {
                const ratingsFormWrapper = d.querySelector("#star-ratings-form-wrapper input[id='" + sStorage + "-star-rating']").checked = true;
            }

            // Setup star rating and send
            function ratingSelection(e) {
                if (e.target !== e.currentTarget) {
                    var oRating = e.target.getAttribute("data-star-ratings-value"),
                        oCountWrapper = d.querySelector(".count-" + oRating),
                        sUUID = oForm.getAttribute("data-uuid"),
                        oMessage = d.getElementById("star-ratings-form-message"),
                        sStorageUpdated = localStorage.getItem("star-ratings-" + sUUID),
                        oOldCountWrapper = d.querySelector(".count-" + sStorageUpdated),
                        iCount = parseInt(oCountWrapper.getAttribute("data-star-ratings-count")),
                        iNewCount = parseInt(oCountWrapper.innerHTML),
                        sTimeReactedNew = new Date().getTime();

                    // If theres already been a star rating made
                    if (sStorageUpdated) {
                        if (oRating === sStorageUpdated) {
                            // Do nothing when clicking on a star rating youve already voted for
                        } else if (oRating != sStorageUpdated) {

                            // Remove previous vote and send
                            sFormActionValue.setAttribute("value", "remove");
                            sFormInput.setAttribute("value", sStorageUpdated);
                            sendData();

                            // Add new vote and send
                            sFormActionValue.setAttribute("value", "add");
                            sFormInput.setAttribute("value", oRating);
                            sendData();

                            // Remove storage item & highlight
                            localStorage.removeItem("star-ratings-" + sUUID);
                            localStorage.removeItem("time-star-rated-" + sUUID);

                            // Show star rating message
                            oMessage.innerHTML = "<p>You rated this " + oRating.replace('star', '') + " star(s)!</p>";
                            oMessage.classList.remove("hide");

                            // Hide message
                            setTimeout(function () {
                                oMessage.classList.add("hide");
                            }, 3000);

                            // Remove old vote
                            if ((parseInt(oOldCountWrapper.innerHTML) - 1) === 0) {
                                oOldCountWrapper.innerHTML = 0;
                                oOldCountWrapper.classList.add("hide");
                            } else {
                                oOldCountWrapper.innerHTML = (parseInt(oOldCountWrapper.innerHTML) - 1);
                            }

                            // Up Count on new vote
                            if (isNaN(iNewCount)) {
                                oCountWrapper.innerHTML = 1;
                                oCountWrapper.classList.remove("hide");
                            } else {
                                oCountWrapper.innerHTML = (iNewCount + 1);
                                oCountWrapper.classList.remove("hide");
                            }

                            // Set local storage items
                            localStorage.setItem("star-ratings-" + sUUID, oRating);
                            localStorage.setItem("time-star-rated-" + sUUID, sTimeReactedNew);
                        }
                    } else {
                        // First star rating being made: set star rating, send
                        sFormInput.value = oRating;
                        sendData();

                        // Display message
                        oMessage.innerHTML = "<p>You rated this " + oRating.replace('star', '') + " star(s)!</p>";
                        oMessage.classList.remove("hide");

                        // Hide message, highlight star rating
                        setTimeout(function () {
                            oMessage.classList.add("hide");
                        }, 3000);

                        // Up Count
                        if (iCount) {
                            oCountWrapper.innerHTML = (iCount + 1);
                            oCountWrapper.classList.remove("hide");
                        } else {
                            oCountWrapper.innerHTML = 1;
                            oCountWrapper.classList.remove("hide");
                        }

                        // Set local storage items
                        localStorage.setItem("star-ratings-" + sUUID, oRating);
                        localStorage.setItem("time-star-rated-" + sUUID, sTimeReactedNew);
                    }
                }
            }

            // Listen for click event
            const divElement = document.getElementById('star-ratings-form-wrapper');
            divElement.addEventListener('click', (event) => {
                const targetElement = event.target;
                if (targetElement.tagName.toLowerCase() === 'input') {
                    ratingSelection(event);
                }
            });

            // Remove local storage count if one exists after cache has cleared (15 min)
            var updatedTime = new Date().getTime();

            if (sStorageTime) {
                if ((updatedTime - sStorageTime) > 1000 * 60 * 15) {
                    localStorage.removeItem("time-star-rated-" + sUUID);
                    oCountWrapper.innerHTML = parseInt(oCountWrapper.getAttribute("data-star-ratings-count"));
                } else {
                    if (isNaN(oCountWrapper.getAttribute("data-star-ratings-count"))) {
                        oCountWrapper.innerHTML = 1;
                    } else {
                        oCountWrapper.innerHTML = ((parseInt(oCountWrapper.getAttribute("data-star-ratings-count"))) + 1);
                    }
                }
            }
        }
    }
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function () {
            starRatings.init();
        });
    } else {
        starRatings.init();
    }
})(window, document);